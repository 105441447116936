<template>
  <UApp>
	  <NuxtLayout>
	  	<NuxtPage />
	  </NuxtLayout>
  </UApp>
</template>
<script setup lang="ts">
useSeoMeta({
  title: 'PCR',
  description: 'Project Controls Reporting',
  ogTitle: 'PCR',
  ogDescription: 'Project Controls Reporting',
  twitterTitle: 'PCR',
  twitterDescription: 'Project Controls Reporting',
})

useHead({
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png'
    }
  ],
  script: [
    {
      src: 'https://cloud.umami.is/script.js',
      defer: true,
      'data-website-id': '6df8e780-8900-4f6f-8817-833ed9588a4b'
    }
  ]
})
</script>

<style>
.list-enter-active{
	transition: all 0.5s ease;
	transition-delay: 0.5s;
}
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
:root {
  --primary: #202020;
  --secondary: #4ade80;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;  /* slightly smaller width */
  background: transparent;  /* make the track transparent */
}

*::-webkit-scrollbar-track {
  background: transparent;  /* make the track transparent */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border: 4px solid var(--primary);  /* increased border to create padding */
  border-radius: 100px;  /* much higher border-radius */
}

/* Optional: Handle hover state */
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary);
  border: 3px solid var(--primary);  /* slightly less border on hover */
}
</style>