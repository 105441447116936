import payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_RTmDnHQIiDweTlhaAniXgjbZUrJa9X_7pB8NnxEjgM4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gbWvwYEO5oE7VNMbn4taOUGQogmfTVe1cn0rZ3Vm2bg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xOYRvRX1UBgbsz2ZIZfoYBO5eCLjXhzU_E_vkPG3G5Y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_JDwSt5mBCdLW05KFx9sazlRsQWWKUjM8lxy8Dlef6lI from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.5.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_O5rbAF8At0fUP6McHt4q4vbtZ7LmJZvg0eKtrZuR8SM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5yF7_37ukRD_hkvU_XnwkNpDLnP7fqWLc3tMK28uykg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_a19FpsCONOVfuemfKAftFMKhI5GfldUtjYcbw9WTQ0c from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CmTqAz5Zz6MM3uZoj_4aJl6FzX480UkrjQ86jgqlbKs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_W9LHOBoZ0Uyg_t_jnea09GW0fRQ0g6BWy_9C5xDLNvo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_ioredis@5.6.0_lightningcss@1_msv2i33z6sgm2yn2g3vumvkz7m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import colors_zsYHkBmzSI3wzBjGtMJVUPnwxC2AMtlvd_lio1oFsc8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@3.0.0_@babel+parser@7.26.10_change-case@5.4.4_db0@0.3.1_embla-carousel@8.5.2_ioredis_potqxbjkmldjvhd35bmls7dpqm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_m4KWDuW2FfrBuSqJ1_tsNHwVWa6_2rW4ufocP9_WP2s from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.1_@types+node@22.13.10_jiti@2.4.2_lightningcss@1.29_j44zkcxxfizjc7uauuqxuqryxu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_tV9lLLm4gG36mduHF2dHIz570d0EU6FyQFJRgfE_d6k from "/opt/buildhome/repo/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.8_2muhteacwbi5zq5zqt2ph4vzre/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import chartjs_jMvVzUemvh_tfjciBPbG9QwiqHy6a1iFl9N3DAQR_U4 from "/opt/buildhome/repo/app/plugins/chartjs.ts";
import presence_plugin_client_SPY_ldThjDlFemdcb_FQlvr3o8lMY6pxIpjeqUolz2Q from "/opt/buildhome/repo/app/plugins/presence-plugin.client.ts";
export default [
  payload_plugin_jcD5OiTKWJEAx3J3PwSvP_PEPq_TY7hGat24oGU2lc4,
  revive_payload_client_RTmDnHQIiDweTlhaAniXgjbZUrJa9X_7pB8NnxEjgM4,
  unhead_gbWvwYEO5oE7VNMbn4taOUGQogmfTVe1cn0rZ3Vm2bg,
  router_xOYRvRX1UBgbsz2ZIZfoYBO5eCLjXhzU_E_vkPG3G5Y,
  supabase_client_JDwSt5mBCdLW05KFx9sazlRsQWWKUjM8lxy8Dlef6lI,
  payload_client_O5rbAF8At0fUP6McHt4q4vbtZ7LmJZvg0eKtrZuR8SM,
  navigation_repaint_client_5yF7_37ukRD_hkvU_XnwkNpDLnP7fqWLc3tMK28uykg,
  check_outdated_build_client_a19FpsCONOVfuemfKAftFMKhI5GfldUtjYcbw9WTQ0c,
  chunk_reload_client_CmTqAz5Zz6MM3uZoj_4aJl6FzX480UkrjQ86jgqlbKs,
  plugin_vue3_N9zpwHXcU8JTY6CwlekYtSaKGwbinW2VWmYVrt9VKGU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_W9LHOBoZ0Uyg_t_jnea09GW0fRQ0g6BWy_9C5xDLNvo,
  colors_zsYHkBmzSI3wzBjGtMJVUPnwxC2AMtlvd_lio1oFsc8,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_m4KWDuW2FfrBuSqJ1_tsNHwVWa6_2rW4ufocP9_WP2s,
  plugin_tV9lLLm4gG36mduHF2dHIz570d0EU6FyQFJRgfE_d6k,
  chartjs_jMvVzUemvh_tfjciBPbG9QwiqHy6a1iFl9N3DAQR_U4,
  presence_plugin_client_SPY_ldThjDlFemdcb_FQlvr3o8lMY6pxIpjeqUolz2Q
]