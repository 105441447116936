import { defineNuxtPlugin } from '#app/nuxt'
import { LazyGlobalAvatar, LazyGlobalBooleanDropdown, LazyGlobalConstraintDropdown, LazyGlobalCraftDropdown, LazyGlobalDateRangePicker, LazyGlobalExportButton, LazyGlobalFileUploadAlert, LazyGlobalFilterTextInput, LazyGlobalFormGroupDisplay, LazyGlobalOnlineUsersButton, LazyGlobalResultsPerPage, LazyGlobalSearch, LazyGlobalTableHourMeter, LazyGlobalTableItrMeter, LazyGlobalTableLoading, LazyGlobalTableScheduleMeter, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["GlobalAvatar", LazyGlobalAvatar],
["GlobalBooleanDropdown", LazyGlobalBooleanDropdown],
["GlobalConstraintDropdown", LazyGlobalConstraintDropdown],
["GlobalCraftDropdown", LazyGlobalCraftDropdown],
["GlobalDateRangePicker", LazyGlobalDateRangePicker],
["GlobalExportButton", LazyGlobalExportButton],
["GlobalFileUploadAlert", LazyGlobalFileUploadAlert],
["GlobalFilterTextInput", LazyGlobalFilterTextInput],
["GlobalFormGroupDisplay", LazyGlobalFormGroupDisplay],
["GlobalOnlineUsersButton", LazyGlobalOnlineUsersButton],
["GlobalResultsPerPage", LazyGlobalResultsPerPage],
["GlobalSearch", LazyGlobalSearch],
["GlobalTableHourMeter", LazyGlobalTableHourMeter],
["GlobalTableItrMeter", LazyGlobalTableItrMeter],
["GlobalTableLoading", LazyGlobalTableLoading],
["GlobalTableScheduleMeter", LazyGlobalTableScheduleMeter],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
