<template>
  <UPage class="px-6">
    <UPageHeader headline="PCR" :title="is404 ? 'Page Not Found' : 'An Error Occurred'" :description="'Error Code: ' + (error?.statusCode.toString() || '500')">
      <template #links>
        <UButton @click="goHome" color="primary">Go Home</UButton>
        <UButton @click="goBack" color="secondary">Go Back</UButton>
      </template>
    </UPageHeader>
  </UPage>
  <UPageBody>
    <div class="w-7/8 md:w-1/2 mx-auto">
    <UCard>
      <template #default>
        <div class="text-lg text-center mb-4 font-semibold flex flex-col items-center">
          <UIcon :name="is404 ? 'i-tabler-error-404' : 'i-heroicons-exclamation-circle'" class="text-4xl mb-2 size-24 text-[var(--ui-error)]" />
          <p>{{ friendlyMessage }}</p>
        </div>
        <UAccordion v-if="!is404" :items :ui="{ root: 'w-full bg-green-600/10 dark:bg-green-400/10 rounded-md px-4' }">
          <template #body>
            <pre>
              {{ error }}
            </pre>
          </template>
        </UAccordion>
      </template>
      <template #footer>
        <div class="flex flex-col sm:flex-row gap-4">
          <UButton @click="goHome" color="primary">Go Home</UButton>
          <UButton @click="goBack" color="secondary">Go Back</UButton>
        </div>
      </template>
    </UCard>
    </div>
  </UPageBody>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import { ref, computed } from 'vue'

// Define props and error handling
const props = defineProps({
  error: Object as () => NuxtError
})

const items = [
  {
    label: 'Technical Details',
    icon: 'i-heroicons-information-circle',
    content: ''
  }
]

// Computed properties
const is404 = computed(() => props.error?.statusCode === 404)

const friendlyMessage = computed(() => {
  if (is404.value) {
    return "We couldn't find the page you were looking for. It might have been moved or deleted."
  }

  // Map common errors to friendly messages
  switch (props.error?.statusCode) {
    case 401:
      return "You need to be logged in to access this page."
    case 403:
      return "You don't have permission to access this resource."
    case 500:
      return "Our server encountered an unexpected error. We've been notified and are working to fix it."
    case 503:
      return "Our service is temporarily unavailable. Please try again later."
    case 504:
      return "The server took too long to respond. Please try again later."
    default:
      return props.error?.message || "Something went wrong. Please try again later."
  }
})

const goHome = () => {
  navigateTo('/')
}

const goBack = () => {
  navigateTo(-1)
}
</script>