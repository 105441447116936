import { useUserStore } from "~~/stores/UserStore"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore()
    const supabaseUser = useSupabaseUser()

    // Handle login page logic
    if (to.path === '/login' && supabaseUser.value) return navigateTo('/')
    if (to.path === '/login' && !supabaseUser.value) return

    // Update presence when user is logged in (only on client side)
    if (process.client && typeof window !== 'undefined' && supabaseUser.value) {
        await userStore.updatePresence()
    }

    // Find the target route in navigation (including nested routes)
    const targetRoute = Navigation
        .flatMap((item): NavigationItem[] =>
            item.children ? [...item.children] : [item]
        )
        .find(item => item.to === to.path)

    // Allow access to public routes (no roles specified) regardless of auth status
    if (!targetRoute?.roles) return

    // Handle protected routes when user is not logged in
    if (!supabaseUser.value) {
        return navigateTo('/login')
    }

    // Get user data if not already loaded
    if (userStore.user.name === '' && supabaseUser.value) {
        await userStore.getUser()
    }
    
    // Get the user's role and handle the possible undefined case
    const userRole = getRoleFromValue(userStore.user.role!.value)
    if (!userRole) {
        return navigateTo('/') // or to an error page
    }

    // Allow admin access to all routes
    if (userRole.value === 'admin') return

    // Check if user's role is allowed for this route
    if (!targetRoute.roles.includes(userRole)) {
        return navigateTo('/') // or to an unauthorized page
    }

    // Check parent route access if it's a child route
    const parentRoute = Navigation.find(item =>
        item.children?.some(child => child.to === to.path)
    )

    if (parentRoute?.roles && !parentRoute.roles.includes(userRole)) {
        return navigateTo('/')
    }
})