import { getRoleFromValue } from "~/utils/permissions"
import type { Database } from "~~/types/database"

interface User {
    name: string
    role: Role | undefined
    id: string
    email: string
}

interface PresenceUser {
    name: string
    role: string
    id: string
    email: string
    status: 'online' | 'away' | 'offline'
}

export const useUserStore = defineStore('user', () => {
    // User state
    const user = reactive<User>({
        name: '',
        role: undefined,
        id: '',
        email: ''
    })
    
    // Presence state
    const isFirstVisit = ref(true)
    const onlineUsers = ref<PresenceUser[]>([])
    const onlineUserCount = ref(0)
    const isPresenceInitialized = ref(false)
    let presenceInterval: NodeJS.Timeout | null = null
    
    // Get user data
    async function getUser() {
        const supabaseUser = useSupabaseUser()
        if (!supabaseUser.value) return
        const supabase = useSupabaseClient<Database>()
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('id', supabaseUser.value.id)
            .maybeSingle()

        if (error) throw error
        if (data) {
            user.name = data.name
            user.role = getRoleFromValue(data.role)
            user.id = data.id
            user.email = data.email
        }
    }
    
    // Initialize presence system
    function initializePresence() {
        // Strict client-side check to prevent SSR execution
        if (isPresenceInitialized.value || typeof window === 'undefined') return
        
        console.log('[UserStore] Initializing presence system')
        isPresenceInitialized.value = true
        
        // Client-only operations
        if (process.client) {
            // Initialize by getting both count and users
            getOnlineUserCount()
            getOnlineUsers()
            
            presenceInterval = setInterval(() => {
                console.log('[UserStore] Running scheduled presence update')
                getOnlineUserCount()
                // Also update the online users list
                getOnlineUsers()
            }, 30000)
              
            // Watch for user changes (without immediate update to prevent duplicate)
            const supabaseUser = useSupabaseUser()
            watch(supabaseUser, async (newUser, oldUser) => {
                // Only update if the user changed (not on initial load)
                if (newUser && newUser !== oldUser) {
                    await updatePresence()
                }
            })
        }
    }
    
    // Clean up the interval
    function cleanupPresence() {
        if (presenceInterval) {
            console.log('[UserStore] Cleaning up presence system')
            clearInterval(presenceInterval)
            presenceInterval = null
            isPresenceInitialized.value = false
        }
    }

    // Update user presence
    async function updatePresence() {
        // Only run on client-side
        if (typeof window === 'undefined') return
        
        const supabaseUser = useSupabaseUser()
        if (!supabaseUser.value) return
        
        console.log('[UserStore] Updating presence')
        const supabase = useSupabaseClient<Database>()

        try {
            const updates: {
                last_activity: string
                session_start?: string
            } = {
                last_activity: new Date().toISOString()
            }

            if (isFirstVisit.value) {
                updates.session_start = new Date().toISOString()
                isFirstVisit.value = false
            }

            const { data: existingPresence } = await supabase
                .from('user_presence')
                .select()
                .eq('user_id', supabaseUser.value.id)
                .single()

            if (!existingPresence) {
                await supabase.from('user_presence').insert({
                    user_id: supabaseUser.value.id,
                    ...updates
                })
            } else {
                await supabase
                    .from('user_presence')
                    .update(updates)
                    .eq('user_id', supabaseUser.value.id)
            }
        } catch (error) {
            console.error('Error updating user presence:', error)
        }
    }

    // Get online user count
    async function getOnlineUserCount() {
        onlineUserCount.value = await $fetch('/api/online-users').then(res => res.count)
    }

    // Get all online users
    async function getOnlineUsers() {
        const supabaseUser = useSupabaseUser()
        if (!supabaseUser.value) return []
        
        const supabase = useSupabaseClient<Database>()
        
        try {
            const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000)
    
            const { data, error } = await supabase
                .from('user_presence')
                .select(`
                    user_id,
                    last_activity,
                    users (
                        id,
                        name,
                        role,
                        email
                    )
                `)
                .gt('last_activity', oneHourAgo.toISOString())
                .order('last_activity', { ascending: false })
    
            if (error) throw error
    
            onlineUsers.value = data.map(presence => {
                return {
                    id: presence.users.id,
                    name: presence.users.name,
                    role: presence.users.role,
                    email: presence.users.email,
                    status: getPresenceStatus(presence.last_activity?.toString() || new Date().toISOString())
                }
            })
    
            return onlineUsers.value
        } catch (error) {
            console.error('Error fetching online users:', error)
            return []
        }
    }

    // Get presence for a specific user
    async function getPresenceByUserId(userId: string) {
        const supabase = useSupabaseClient<Database>()
        
        const { data, error } = await supabase
            .from('user_presence')
            .select('last_activity, users (name, role, id, email)')
            .eq('user_id', userId)
            .single()

        if (error) throw error

        return {
            name: data.users.name,
            role: data.users.role,
            id: data.users.id,
            email: data.users.email,
            status: data.last_activity ? getPresenceStatus(data.last_activity) : 'offline'
        }
    }
    
    return { 
        // User properties and methods
        user, 
        getUser,
        
        // Presence properties and methods
        onlineUsers,
        onlineUserCount,
        isPresenceInitialized,
        initializePresence,
        cleanupPresence,
        updatePresence,
        getOnlineUserCount,
        getOnlineUsers,
        getPresenceByUserId
    }
})

// Helper function to determine user status based on last activity
function getPresenceStatus(lastActivity: string): 'online' | 'away' | 'offline' {
    const now = new Date()
    const fifteenMinutesAgo = new Date(now.getTime() - 15 * 60 * 1000)
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000)

    const lastActivityDate = new Date(lastActivity)
    
    if (lastActivityDate > fifteenMinutesAgo) return 'online'
    else if (lastActivityDate > oneHourAgo) return 'away'
    else return 'offline'
}