import { useUserStore } from "~~/stores/UserStore"

export default defineNuxtPlugin({
  name: 'presence-plugin',
  setup(nuxtApp) {
    // Only run on client-side with strict browser check
    if (process.client && typeof window !== 'undefined') {
      // Wait for client side to be fully loaded
      nuxtApp.hook('app:mounted', () => {
        const userStore = useUserStore()
        
        // Initialize presence system
        userStore.initializePresence()
      })
      
      // Clean up when the app is unmounted
      nuxtApp.hook('app:unmount', () => {
        const userStore = useUserStore()
        userStore.cleanupPresence()
      })
    }
  }
})