
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutQTGs1Tivaf63aUdG_vyL6eqqmZEovM3XmtcZkJgI5x8Meta } from "/opt/buildhome/repo/app/pages/about.vue?macro=true";
import { default as aconexXtoMET_lTp3DP9lOnb3sxwcBB6tge_45cRGEPJzZNicoUMeta } from "/opt/buildhome/repo/app/pages/data/aconex.vue?macro=true";
import { default as itrsqoTc9uhfjFv3_5s2z8If2Q8lcQfuyuqSHNZB9_8FjasMeta } from "/opt/buildhome/repo/app/pages/data/itrs.vue?macro=true";
import { default as pcnVNF6Ngk5Em_45YUX5zIiNvOX8q4nNmkRPjWUMmqR_C5Z0Meta } from "/opt/buildhome/repo/app/pages/data/pcn.vue?macro=true";
import { default as qtraxLW3BXrE4Y0tZrDpLWWppQoaM1heQg002LddXwqY6248Meta } from "/opt/buildhome/repo/app/pages/data/qtrax.vue?macro=true";
import { default as ratesVA7jZ72qHOB7gQFVvxkRIGto41_45eLMu9msgcxl3mr68Meta } from "/opt/buildhome/repo/app/pages/data/rates.vue?macro=true";
import { default as scheduleNhRgUIUPe4OCgQFwRhq0Ho2b7J4fj8iTxOqBrT4QqMoMeta } from "/opt/buildhome/repo/app/pages/data/schedule.vue?macro=true";
import { default as uploadAGfQApEkI3eSwYs6iSFjLGrkXjP4wcgQCCSWfwrOclgMeta } from "/opt/buildhome/repo/app/pages/data/upload.vue?macro=true";
import { default as indexBUquJ6FW9selPoMmXmiQ06xberjjtjDDexnQK6NGwH0Meta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as loginGl0mRqaq_45Lxq2m4Ffurs3Awcsqo0YQUvhoY_AZvpXl4Meta } from "/opt/buildhome/repo/app/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_93hML7h8bPcOJoyJ1sddAZlxNcw7XMxGTMJcPzGO_45iJyIMeta } from "/opt/buildhome/repo/app/pages/packages-old/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93S63ODGgmaSyaq8W4FICtTcsm7ZxY4f3ltIMlXGu4yIQMeta } from "/opt/buildhome/repo/app/pages/packages/[...slug].vue?macro=true";
import { default as pipe_45testing4NHhxWVaJ6D25TmIJFRFoYIAZVB_Ypy_45WicC6eSkkqIMeta } from "/opt/buildhome/repo/app/pages/pipe-testing.vue?macro=true";
import { default as ebuiltsoik9uWayliARD38PLM6PXINbSjm9ZR3DGLbzR7HwHWUMeta } from "/opt/buildhome/repo/app/pages/planners/ebuilts.vue?macro=true";
import { default as package_45updates2WL0L84qplT65_45sBTT7qylQbi9fKrOB_O7suyRS_8uAMeta } from "/opt/buildhome/repo/app/pages/planners/package-updates.vue?macro=true";
import { default as settings_Wn8uZb0L4juzNHMzJy5ywS7_nb5H52zAe9zOnWmvOwMeta } from "/opt/buildhome/repo/app/pages/settings.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/buildhome/repo/app/pages/about.vue")
  },
  {
    name: "data-aconex",
    path: "/data/aconex",
    component: () => import("/opt/buildhome/repo/app/pages/data/aconex.vue")
  },
  {
    name: "data-itrs",
    path: "/data/itrs",
    component: () => import("/opt/buildhome/repo/app/pages/data/itrs.vue")
  },
  {
    name: "data-pcn",
    path: "/data/pcn",
    component: () => import("/opt/buildhome/repo/app/pages/data/pcn.vue")
  },
  {
    name: "data-qtrax",
    path: "/data/qtrax",
    component: () => import("/opt/buildhome/repo/app/pages/data/qtrax.vue")
  },
  {
    name: "data-rates",
    path: "/data/rates",
    component: () => import("/opt/buildhome/repo/app/pages/data/rates.vue")
  },
  {
    name: "data-schedule",
    path: "/data/schedule",
    component: () => import("/opt/buildhome/repo/app/pages/data/schedule.vue")
  },
  {
    name: "data-upload",
    path: "/data/upload",
    component: () => import("/opt/buildhome/repo/app/pages/data/upload.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/app/pages/login.vue")
  },
  {
    name: "packages-old-slug",
    path: "/packages-old/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/app/pages/packages-old/[...slug].vue")
  },
  {
    name: "packages-slug",
    path: "/packages/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/app/pages/packages/[...slug].vue")
  },
  {
    name: "pipe-testing",
    path: "/pipe-testing",
    component: () => import("/opt/buildhome/repo/app/pages/pipe-testing.vue")
  },
  {
    name: "planners-ebuilts",
    path: "/planners/ebuilts",
    component: () => import("/opt/buildhome/repo/app/pages/planners/ebuilts.vue")
  },
  {
    name: "planners-package-updates",
    path: "/planners/package-updates",
    component: () => import("/opt/buildhome/repo/app/pages/planners/package-updates.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/app/pages/settings.vue")
  }
]