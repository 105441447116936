export default defineAppConfig({
	ui: {
		colors: {
			primary: 'green',
			neutral: 'neutral',
			secondary: 'violet',
			tertiary: 'teal',
			quaternary: 'orange',
			quinary: 'pink',
		},
		button: {
			slots: {
				base: 'hover:cursor-pointer',
			}
		}
	}
})